import React from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import './AIgenerated.scss';

const AIgeneratedImages = [
  {
    title: 'AI Santa',
    imageSrc: require('../../assets/santa2.jpg'),
    link: 'https://walterpaul-bebirian.pixels.com/featured/df41-walter-paul-bebirian.html',
  },
  {
    title: 'AI Santa x Trump',
    imageSrc: require('../../assets/santa3.jpg'),
    link: 'https://walterpaul-bebirian.pixels.com/featured/df51-walter-paul-bebirian.html',
  },
  {
    title: 'AI Santa x Space',
    imageSrc: require('../../assets/santa4.jpg'),
    link: 'https://walterpaul-bebirian.pixels.com/featured/df43-walter-paul-bebirian.html',
  },
  {
    title: 'AI Anime',
    imageSrc: require('../../assets/ai1.jpg'),
    link: 'https://walterpaul-bebirian.pixels.com/featured/1-vk5041-walter-paul-bebirian.html',
  },
  {
    title: 'AI Anime Black and White',
    imageSrc: require('../../assets/ai2.jpg'),
    link: 'https://walterpaul-bebirian.pixels.com/featured/1-vk5040-walter-paul-bebirian.html',
  },
  {
    title: 'Color Generation',
    imageSrc: require('../../assets/ai3.jpg'),
    link: 'https://walterpaul-bebirian.pixels.com/featured/df254-walter-paul-bebirian.html',
  },
  {
    title: 'Pop Timistic',
    imageSrc: require('../../assets/ai4.jpg'),
    link: 'https://walterpaul-bebirian.pixels.com/featured/df251-luanne-wordsmith-walter-paul-bebirian.html',
  },
  {
    title: 'Anime Blur',
    imageSrc: require('../../assets/ai5.jpg'),
    link: 'https://walterpaul-bebirian.pixels.com/featured/1-vk5037-walter-paul-bebirian.html',
  },
  {
    title: 'AI Idea',
    imageSrc: require('../../assets/ai6.jpg'),
    link: 'https://walterpaul-bebirian.pixels.com/featured/df264-walter-paul-bebirian.html',
  },
];

const AIgenerated = () => {
  return (
    <>
      <h2 className="head-text">AI Generated Images</h2>
      <div className="ai_container">
        {AIgeneratedImages.map((image, index) => (
          <a
            href={image.link}
            target="_blank"
            key={index}
            style={{ textDecoration: 'none' }}
          >
            <div className="nft">
              <div className="main">
                <img className="tokenImage" src={image.imageSrc} alt="NFT" />
                <p className="description">{image.title}</p>
                <hr />
              </div>
            </div>
          </a>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(AIgenerated, 'ai_container'),
  'aigenerated',
  'app__whitebg',
);
