import { React } from 'react';
import { App, AppWrap, MotionWrap } from '../../wrapper';
import { motion } from 'framer-motion';
import './Xmas.scss';

const Xmas = () => {
  return (
    <>
      <div className="info_container">
        <h2 className="head-text xmas">
          Armenian Christmas
          <br />
          <span className="sub-heading-xmas">
            When it's Christmas in Armenia, it's Christmas everyday!
          </span>
        </h2>
        <h3
          className="sub-heading-xmas"
          style={{
            borderBottom: '1px solid #000000',
          }}
        >
          Why Celebrate Christmas on January 6th?
        </h3>
        <ul>
          <li>
            <p className="p-text xmas">
              The Armenian Apostolic Church celebrates Christmas on January 6th.
            </p>
          </li>
          <li>
            <p className="p-text xmas">
              Armenians adopted Christianity as their national regligion in 301
              A.D. and continued to celebrate Christmas on the same date.
            </p>
          </li>
          <li>
            <p className="p-text xmas">
              In the Armenian Church, January 6th is the Feast of the Theophany,
              or Revelation of Christ. It commemorates both the birth of Christ
              and his baptism by John the Baptist.
            </p>
          </li>
        </ul>
        <div className="xmas_btn_wrapper">
          <a
            href="https://walterpaul-bebirian.pixels.com/featured/df193-walter-paul-bebirian.html"
            target="_blank"
          >
            <button className="xmas__btn">Learn More</button>
          </a>
        </div>
      </div>
      <div className="xmas__container">
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.04 }}
          transition={{ duration: 0.5, type: 'tween' }}
          className="header-xmas-now-btn p-text"
        >
          <a
            href="https://walterpaul-bebirian.pixels.com/featured/df193-walter-paul-bebirian.html"
            target="_blank"
            className="xmas_images"
          >
            <img
              src={require('../../assets/santa.jpg')}
              alt="Armenian Christmas"
              style={{ borderRadius: '20px' }}
            />
          </a>
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(Xmas, 'app__xmas'), 'xmas', 'app__whitebg');
