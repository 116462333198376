import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import { images } from '../../constants';

import './Companies.scss';

const Companies = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {}, []);

  const brands = [
    {
      _id: '1',
      name: 'COLLWEB',
      imgUrl: images.collweb,
      linkUrl: 'https://walter-paul-bebirian.collweb.art/#arts',
    },
    {
      _id: '2',
      name: 'Saatchi Art',
      imgUrl: images.saatchi,
      linkUrl: 'https://575488trillion.com/image/135499187.jpg',
    },
    {
      _id: '3',
      name: 'IBM',
      imgUrl: images.ibm,
      linkUrl: 'https://www.ibm.com/us-en?ar=1',
    },
    {
      _id: '4',
      name: 'Ryze',
      imgUrl: images.ryze,
      linkUrl: 'https://www.ryze.com/go/walterpaulBebirian',
    },
    {
      _id: '5',
      name: 'Volord',
      imgUrl: images.volord,
      linkUrl: 'https://volord.com/',
    },
    {
      _id: '6',
      name: 'Verified Artist',
      imgUrl: images.verifiedArtist,
      linkUrl: 'https://www.artistsinfo.co.uk/artist/walter-paul-bebirian/',
    },
    {
      _id: '7',
      name: 'Paypal',
      imgUrl: images.paypal,
      linkUrl: 'https://www.paypal.com/us/cshelp/contact-us',
    },
    {
      _id: '8',
      name: 'Art Net',
      imgUrl: images.artNet,
      linkUrl:
        'https://www.artnet.com/search/ahgalleries/?q=The%20volord%20kingdom%20art%20collection',
    },
    {
      _id: '9',
      name: 'twitter',
      imgUrl: images.twitter,
      linkUrl: 'https://x.com/volordkingdom',
    },
    {
      _id: '10',
      name: 'facebook',
      imgUrl: images.facebook,
      linkUrl: 'https://www.facebook.com/walterpaulbebirian',
    },
    {
      _id: '11',
      name: 'LinkedIn',
      imgUrl: images.linkedin,
      linkUrl: 'https://www.linkedin.com/in/walterpaulbebirian/',
    },
    {
      _id: '12',
      name: 'Instagram',
      imgUrl: images.instagram,
      linkUrl: 'https://www.instagram.com/volordkingdom/',
    },

    {
      _id: '13',
      name: 'Tumblr',
      imgUrl: images.tumblr,
      linkUrl: 'http://walterpaulbebirian.tumblr.com/',
    },
    {
      _id: '14',
      name: 'Pinterest',
      imgUrl: images.pinterest,
      linkUrl: 'https://www.pinterest.com/bebirian/art/',
    },
    {
      _id: '15',
      name: 'Sokule',
      imgUrl: images.sokule,
      linkUrl: 'https://www.sokule.com/postit/bebirian',
    },
  ];

  return (
    <>
      <div className="app__companies-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0.9, 1] }}
            transition={{ duration: 0.5, type: 'tween' }}
            key={brand._id}
          >
            <a href={brand.linkUrl} target="_blank">
              <img src={brand.imgUrl} alt={brand.name} />
            </a>
          </motion.div>
        ))}
      </div>
      <motion.div
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.04 }}
        transition={{ duration: 0.5, type: 'tween' }}
      >
        <a
          target="_blank"
          href="https://walterpaul-bebirian.pixels.com/art"
          style={{ textDecoration: 'none' }}
        >
          <button
            className="app__header-button"
            style={{ marginTop: 40 }}
            type="button"
          >
            <span>Shop Now</span>
          </button>
        </a>
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Companies, 'app__companies'),
  'companies',
  'app__whitebg',
);
