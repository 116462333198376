import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './Money.scss';

const Money = () => {
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  return (
    <>
      {isHomePage && (
        <h2 className="head-text">
          Finance
          <br />
          <span> Money and Art</span>
        </h2>
      )}
      {!isHomePage && (
        <h2 className="head-text" style={{ marginTop: '50px' }}>
          Finance
          <br />
          <span>Money and Art</span>
        </h2>
      )}

      <div className="app__profiles">
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.04 }}
          transition={{ duration: 0.5, type: 'tween' }}
          className="app__profile-item"
        >
          <img
            src={require('../../assets/money_art.jpg')}
            alt="Money and Art"
          />
        </motion.div>
      </div>
      <div className="money__tools-wrapper">
        <div className="money__tools">
          <div className="money__tools-item">
            <a
              style={{ textDecoration: 'none' }}
              href="https://575488trillion.com/files/135502730.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="money__bold_text"
            >
              The Supreme Investment Rule
            </a>
            <p className="money__paragraph">
              "Time is not - Only Life Is - Without Life There is no Time"
            </p>
          </div>
          <div className="money__tools-item">
            <a
              style={{ textDecoration: 'none' }}
              href="https://575488trillion.com/files/135502731.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="money__bold_text"
            >
              Money Tool
            </a>
            <p className="money__paragraph">
              "To begin with - it has been my intention to more deeply
              understand what has been previously referred to as an obsession
              with money"
            </p>
          </div>
          <div className="money__tools-item">
            <a
              style={{ textDecoration: 'none' }}
              href="https://575488trillion.com/files/135502737.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="money__bold_text"
            >
              The Art of Time & Money
            </a>
            <p className="money__paragraph">
              Which although seems to allude all forms of inspection is really
              most probably the cause of any distress...
            </p>
          </div>
        </div>
        <div className="money__tools">
          <div className="money__tools-item">
            <a
              style={{ textDecoration: 'none' }}
              href="http://www.volord.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="money__bold_text"
            >
              Thoughts on the Speed of Business
            </a>
            <p className="money__paragraph">
              "Time is not - Only Life Is - Without Life There is no Time"
            </p>
          </div>
          <div className="money__tools-item">
            <a
              style={{ textDecoration: 'none' }}
              href="https://575488trillion.com/files/135502750.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="money__bold_text"
            >
              Diversification Series
            </a>
            <p className="money__paragraph">
              "A hard look at what it can mean!"
            </p>
          </div>
          <div className="money__tools-item">
            <a
              style={{ textDecoration: 'none' }}
              href="https://575488trillion.com/files/135502751.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="money__bold_text"
            >
              Operation and Investing
            </a>
            <p className="money__paragraph">
              "Although there are many many people who are writing these days
              about..."
            </p>
          </div>
          <div className="money__tools-item">
            <a
              style={{ textDecoration: 'none' }}
              href="https://575488trillion.com/files/135807134.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="money__bold_text"
            >
              The Doubling Project
            </a>
            <p className="money__paragraph">
              "GIVEN THE CHOICE BETWEEN A MILLION DOLLARS RIGHT NOW - OR A
              DOLLAR THAT..."
            </p>
          </div>
        </div>
      </div>

      <motion.div
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.04 }}
        transition={{ duration: 0.5, type: 'tween' }}
      >
        <a
          target="_blank"
          href="https://walterpaul-bebirian.pixels.com/art"
          style={{ textDecoration: 'none' }}
        >
          <button
            className="app__header-button"
            style={{ marginTop: 40 }}
            type="button"
          >
            <span>Shop Now</span>
          </button>
        </a>
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Money, 'app__about'),
  'money',
  'app__whitebg',
);
